.table {
  thead {
    th {
      border-bottom-width: $table-border-width;
    }
  }

  &:not(.table-dark) {
    thead:not(.thead-dark) {
      color: $headings-color;
    }
  }
/* PR jaky je duvod rusit ohraniceni? to prece chceme i kdyz je to barevne..
  tr[class*="table-"] {
    td,
    th,
    & + tr td,
    & + tr th {
      border: 0;
    }
  }
 */
  th {
    font-weight: 500;
  }

  &:not(.table-bordered) {
    & > thead,
    & > tbody {
      &:first-child {
        th, td {
          border-top: 0;
        }
      }
    }
  }
}

/* PR opravdu kompaktni tabulka */
.table-xs {
  th,
  td {
    padding: $table-cell-padding-xs;
  }
}
/* PR nezalamovaci tabulka */
.table-nowrap {
  th,td {
    white-space: nowrap;
  }
}


.data-table {
  table {
    th {
      user-select: none;
      cursor: pointer;
      position: relative;

      & > i.fa {
        position: absolute;
        font-style: normal;
        right: 0.3rem;
        bottom: 0.6rem;
        font-size: 1.4rem;

        &:before {
          font-family: $font-family-icon;
        }

        &.fa-chevron-up:before {
          content: '\f1ce';
        }

        &.fa-chevron-down:before {
          content: '\f1cd';
        }
      }
    }
  }

  tr {
    & > td,
    & > th {
      &:first-child {
        padding-left: $card-spacer-x;
      }

      &:last-child {
        padding-right: $card-spacer-x;
      }
    }
  }
}
