// Font Icon (for before/after content)
@mixin font-icon($content, $font-size) {
  font-family: $font-family-icon;
  content: $content;
  font-size: $font-size;
}


// CSS Animations based on animate.css
@mixin animate($name, $duration) {
  -webkit-animation-name: $name;
  animation-name: $name;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


// Card
@mixin card() {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $card-shadow;
}


// Placeholder
@mixin placeholder {
  &::-webkit-input-placeholder { @content }
  &:-moz-placeholder { @content }
  &::-moz-placeholder { @content }
  &:-ms-input-placeholder { @content }
}


// Select
@mixin select-bg() {
  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: 1;
    right: 0;
    bottom: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent transparent #d1d1d1 transparent;
  }
}


// User Select
@mixin user-select($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}


// Background Cover
@mixin bg-cover($image) {
  background-image: url($image);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

@mixin bg-cover-inline() {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

// PR presunuto sem kvuli deprecation warning v BS4

// Sizing shortcuts
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
  //@include deprecate("`size()`", "v4.3.0", "v5");
}