.modal-content {
  box-shadow: $modal-content-box-shadow-sm-up;
}

.modal-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.modal-footer {
  padding-top: 0.8rem;

  & > .btn-link {
    color: $headings-color;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    border-radius: $border-radius;

    &:hover,
    &:focus {
      background-color: $gray-100;
    }
  }
}