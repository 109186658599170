accordion {
  .card {
    margin: 0;
    box-shadow: none;
  }

  .card-header {
    padding: 0;
    transition: background-color 300ms;

    &:not(:hover) {
      background-color: transparent;
    }

    button {
      width: 100%;
      text-align: left;
      padding: 0.7rem 1.15rem;

      &, &:hover {
        color: $headings-color;
      }
    }
  }

  .card-body {
    padding: 1.25rem 1.25rem;
  }
}

accordion-group {
  border: 1px solid $accordion-border-color;
  margin-bottom: -1px;
}