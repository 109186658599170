$ct-line-width: 1.5;
$ct-point-size: 8px;
$ct-grid-color: #eaf6f9;
$ct-grid-dasharray: 0;
$ct-text-color: $text-muted;
$ct-series-colors: (
  $green,
  $blue,
  $yellow,
  $purple,
  $teal,
  $pink,
  $red,
  $deep-orange,
  $cyan,
  $deep-purple,
  $light-green,
  $indigo,
  $blue-grey,
  $lime,
  $brown
);

/* PR fix - chartist je asi dead, ale neaktualnost jeho settings, muzem vyresit tim ze je zkopirujem sem a opravime zde */
$ct-scales: ((1), calc(15/16), calc(8/9), calc(5/6), calc(4/5), calc(3/4), calc(2/3), calc(5/8), calc(1/1.618), calc(3/5), calc(9/16), calc(8/15), calc(1/2), calc(2/5), calc(3/8), calc(1/3), calc(1/4)) !default;
$ct-scales-names: (ct-square, ct-minor-second, ct-major-second, ct-minor-third, ct-major-third, ct-perfect-fourth, ct-perfect-fifth, ct-minor-sixth, ct-golden-section, ct-major-sixth, ct-minor-seventh, ct-major-seventh, ct-octave, ct-major-tenth, ct-major-eleventh, ct-major-twelfth, ct-double-octave) !default;
$ct-container-ratio: calc(1/1.618) !default;
/* ^^^^ PR fix ^^^ */
@import "../../../../node_modules/chartist/dist/scss/chartist.scss";

.ct-legend {
  position: relative;
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 1rem 0 -0.5rem 0;
  text-align: center;

  & > li {
    position: relative;
    display: inline-block;
    color: $text-muted;
    font-size: 0.9rem;
    padding: 5px 10px;
    border-radius: $border-radius;
    cursor: pointer;

    &:hover {
      background-color: $gray-50;
    }

    &.inactive {
      opacity: 0.5;
    }

    &:before {
      content: '';
      @include size(9px, 9px);
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: 4px;
      margin-right: 5px;
    }
  }

  @for $i from 0 to length($ct-series-colors) {
    .ct-series-#{$i}:before {
      background-color: nth($ct-series-colors, $i + 1);
      border-color: nth($ct-series-colors, $i + 1);
    }
  }
}

