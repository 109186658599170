.contacts {
  @include media-breakpoint-down(xs) {
    margin: 0 -5px;

    & > [class*="col-"] {
      padding: 0 5px;
    }
  }
}

.contacts__item {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $card-shadow;
  padding: 1.8rem 1.5rem 1.15rem;
  text-align: center;
  margin-bottom: $grid-gutter-width;

  &:hover {
    .contacts__img > img {
      transform: scale(1.1);
    }
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 10px;
  }
}

.contacts__img {
  display: block;
  margin-bottom: 1.1rem;

  & > img {
    max-width: 120px;
    max-height: 120px;
    width: 100%;
    border-radius: 50%;
    transition: transform 300ms;
  }
}

.contacts__info {
  strong,
  small {
    @include text-truncate();
    display: block;
  }

  strong {
    font-weight: normal;
    color: $headings-color;
  }

  small {
    font-size: 0.9rem;
    color: $text-muted;
  }
}

.contacts__btn {
  margin-top: 1rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  border: 0;
  line-height: 100%;
  background-color: transparent;
  color: $body-color;
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: $border-radius;
  transition: background-color 300ms, color 300ms;

  &:hover {
    background-color: $gray-100;
    color: $headings-color;
  }
}


// New contact
.new-contact__header {
  background-color: $body-bg;
  text-align: center;
  padding: 2.5rem 0;
  border-radius: $card-border-radius $card-border-radius 0 0;
  border: 0.35rem solid $white;
  position: relative;
}

.new-contact__img {
  border-radius: 50%;
  box-shadow: 0 0 0 0.35rem $white;
  @include size(150px, 150px);
  @include media-breakpoint-down(sm) {
    @include size(100px, 100px);
  }
}

.new-contact__upload {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  font-size: 1.5rem;
  @include size(40px, 40px);
  border-radius: 50%;
  line-height: 42px;
  background-color: $gray-400;
  color: $white;
  transition: background-color 300ms;

  &:hover {
    color: $white;
    background-color: $gray-500;
  }
}